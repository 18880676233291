/**
 * Booking Store
 * ----------------------------
 *
 */
export const useBookingStore = defineStore('booking', () => {
    /**
     * References to products, passengers, offers and vouchers. They
     * are re-created from the data of a booking
     * ------------------------------------------------------------------------------------------
     */
    const products = ref<Products>({});
    const passengers = ref<Passenger[]>([]);
    const offers = ref<Offers>({});
    const optionalOffers = ref<OptionalOffer[]>([]);

    /**
     * Vouchers
     * ------------------------------------------------------------------------------------------
     */
    const voucherCode = ref<VoucherCode>({ voucherCode: '' });
    const vouchers = ref<Voucher[]>([]);

    /**
     * Other
     * ------------------------------------------------------------------------------------------
     */
    const termsAndConditions = ref<TermsAndCondition[]>([]);
    const acceptedTermsAndConditions = ref<AcceptedTermsAndConditions>({ });

    /**
     * Booking
     * ------------------------------------------------------------------------------------------
     */
    const booking = ref<CheckoutBooking>();

    async function loadBooking(id: string) {
        let ok = false;
        ({
            ok,
            data: {
                booking: booking.value,
                passengers: passengers.value,
                offers: offers.value,
                optionalOffers: optionalOffers.value,
                products: products.value,
                vouchers: vouchers.value,
                termsAndConditions: termsAndConditions.value,
            },
        } = await apiLoadBooking(id));

        setAcceptedTermsAndConditions();

        return ok;
    }

    /**
     * Based on the available terms and conditions of the booking and
     * possibly existing terms on the route query, set the accepted terms.
     * This creates the initial state of the accepted terms. It's overwritten, whenever
     * the booking is reloaded. In case you're redirected from the payment provider,
     * the accepted terms are set based on the query parameters.
     */
    function setAcceptedTermsAndConditions() {
        acceptedTermsAndConditions.value = termsAndConditions.value.reduce((acc, terms) => {
            acc[terms.id] = false;
            return acc;
        }, {} as AcceptedTermsAndConditions);

        const route = useRoute();
        const acceptedTerms = route.query.acceptedTerms as string;

        if (acceptedTerms) {
            acceptedTerms.split(',').forEach((termId) => {
                if (termId in acceptedTermsAndConditions.value) {
                    acceptedTermsAndConditions.value[termId] = true;
                }
            });
        }
    }

    /**
     * ------------------------------------------------------------------------------------------
     */

    function reset() {
        products.value = {};
        offers.value = {};
        optionalOffers.value = [];
        passengers.value = [];
        booking.value = undefined;
        termsAndConditions.value = [];
        acceptedTermsAndConditions.value = {};
    }

    return {
        // Booking
        booking,
        loadBooking,

        // References
        products,
        passengers,
        offers,
        optionalOffers,

        // Vouchers
        vouchers,
        voucherCode,

        // Terms
        termsAndConditions,
        acceptedTermsAndConditions,

        reset,
    };
}, {
    persist: storePersist.storePersistOptions,
});
